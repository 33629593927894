/*
 * @Author: qinsensen
 * @Date: 2020-06-23 15:32:02
 * @LastEditors: wangmengfei
 * @LastEditTime: 2022-04-08 13:52:18
 * @Description: 
 */ 
import React, { useState } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './routes';
import './style/css/app.less'
function App() {
  const [showDelog,setshowDelog] = useState(false)
  return (
    <BrowserRouter>
      <Switch>
        { renderRoutes(routes)}
      </Switch>
      <div className="delog-wrap" onClick={() => { setshowDelog(!showDelog) }}>
          <img src={require('./assets/img/delog/button.png')} alt="" />
      </div>
      
      {
        showDelog && (<div className="delog">
        <p>长按识别二维码</p>
        <p className="font1">添加专属客服咨询</p>
        <img src={require('./assets/img/delog/WX.png')} alt="" />
        <p>客服热线： 18621398329</p>
      </div>
      )}
      {
        showDelog && (<div className="delogBg" onClick={() => { setshowDelog(!showDelog) }}>
      </div>
      )}
    </BrowserRouter>
  );
}

export default App;
