/*
 * @Author: your name
 * @Date: 2020-06-25 22:22:38
 * @LastEditTime: 2020-06-25 22:43:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \official-website\src\pages\Base\Base.js
 */
import React from 'react'
import { renderRoutes } from 'react-router-config'
import TopMenu from '../../components/TopMenu'
import linkData from '../../const/linkData'
import '../../style/pages/Base.less'
function Base(props) {
    const { route: { routes } } = props
    const currentIndex = linkData.findIndex(item => item.path === props.location.pathname)
    return (
        <div className='base_wrapper'>
            <TopMenu currentIndex={currentIndex} />
            { renderRoutes(routes)}
        </div>
    )
}

export default Base
