import React, { useState, useRef } from 'react'
import '../style/components/TopMenu.less';
import { withRouter } from 'react-router-dom'
import { routeJump } from '../utils/util.route.handle';
import linkData from '../const/linkData'
const TopMenu = (props) => {
    const [currentIndex, setCurrentIndex] = useState(props.currentIndex)
    const boxRef = useRef()
    const [linkList] = useState(linkData)
    // 点击tab
    const filterBarClick = (item, index) => {
        if (currentIndex === index) {
            return
        } else {
            setCurrentIndex(index)
            //获取标签父元素DOM
            let contentDom = boxRef.current;
            //获取点击时当前标签的DOM
            let valDom = contentDom.children[index];
            //计算当前标签到最左侧的宽度
            let valLeft = valDom.offsetLeft;
            //计算当前标签本身的宽度
            let valWidth = valDom.clientWidth;
            //当前标签中心点到最左侧的距离
            let valCenter = valLeft + valWidth / 2;
            //可视屏幕宽度
            let clientWidth = document.querySelector('body').offsetWidth;
            //可视屏幕中心点
            // let center = (clientWidth - 30) / 2;
            let center = clientWidth / 2;
            //计算当前标签中心点和屏幕中心点的偏移量 然后滚动相应的距离
            if (valCenter > center) {
                contentDom.scrollTo({
                    left: valCenter - center,
                    behavior: 'smooth'
                });
            } else {
                contentDom.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }
            routeJump(props, {
                url: linkList[index].path
            })
        }

    }
    return (
        <div className='top_menu_container'>
            <div className="content_box" ref={boxRef}>
                {
                    linkList.map((item, index) => {
                        return (
                            <div className={`menu_item ${currentIndex === index ? 'active_link' : ''}`} key={index} onClick={() => filterBarClick(item, index)}>{item.title}</div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default withRouter(TopMenu)
