const linkData = [
    {
        title: '公司介绍',
        path: '/home'
    },
    {
        title: '服务体系',
        path: '/service'
    },
    {
        title: '核心优势',
        path: '/advantage'
    },
    {
        title: '合伙人招募',
        path: '/recruitment'
    },
    {
        title: '联系我们',
        path: '/about'
    },

]
export default linkData